
.search-bar{

}.filtering-method {}.dropdown {
    margin-left: -70px;
    z-index: 50;
}

.search-bar{}.

.search-bar input[type="text"] {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    background-color: #f5f5f5;
    width: 85%;
    padding-left: 5px;
    padding-bottom: 5px;
    margin: 5px 5px 15px 5px;
    font-size: 12px;
    border-bottom: 1px solid #eeeeee;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.search-bar input[type="text"]:focus {
    outline: none;
    border-bottom: 1px solid #ffc125;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.search-bar .search-input {
    color: #777777;
    padding-left: 0;
}
.search-bar .search-input .dropdown a {
    color: #777777;
}
.search-bar .search-input .dropdown .fa-filter {
    padding: 5px 5px 0 5px;
}
.search-bar .search-input .dropdown-menu {
    -webkit-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2) ;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2) ;
    border-radius: 0;
    top: -6%;
    padding: 0;
    left: 20px;
}
.search-bar .search-input .dropdown-menu li a {
    padding: 8px 20px;
    font-size: 12px;
}
.search-bar .search-input .dropdown-menu:before {
    position: absolute;
    top: 5px;
    left: -8px;
    display: inline-block;
    border-top: 6px solid transparent;
    border-right: 7px solid #ccc;
    border-bottom: 6px solid transparent;
    content: '';
}
.search-bar .search-input .dropdown-menu:after {
    position: absolute;
    top: 5px;
    left: -6px;
    display: inline-block;
    border-top: 6px solid transparent;
    border-right: 6px solid #ffffff;
    border-bottom: 6px solid transparent;
    content: '';
}
.search-bar .search-input .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}
.search-bar .search-input span {
    position: relative;
}
.search-bar .search-input .filtered-by-id, .search-bar .search-input .filtered-by-name, .search-bar .search-input .filtered-by-creator {
    color: #f5f5f5;
    font-size: 12px;
    background-color: #ffc125;
    padding: 3px 6px 3px 10px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
    position: absolute;
    right: 20px;
}
.search-bar .search-input .filter-close {
    background-color: transparent;
    padding: 3px;
    margin-top: -1px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
}
.search-bar .search-input .filter-close:hover {
    background-color: #ffc125;
    cursor: pointer;
}
.search-bar .filtering-method .dropdown {
    margin-left: -70px;
    z-index: 50;
}
.search-bar .filter-menu .dropdown {
    z-index: 40;
}
.search-bar{} .filter-menu{} .dropdown .dropdown-menu {
    border-radius: 0;
    top: 77%;
    padding: 0;
    -webkit-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2) ;
    -moz-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2) ;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2) ;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    max-height: 0;
    display: block;
    overflow: hidden;
    opacity: 0;
}
.search-bar .filter-menu .dropdown .dropdown-menu li a {
    padding: 8px 20px;
    font-size: 12px;
}
.search-bar .filter-menu .dropdown .dropdown-menu li a .fa.initial.app-state {
    color: #ffc125;
}

.search-bar .filter-menu .dropdown .dropdown-menu li a .fa.any.status.app-state {
    color: #000;
}
.search-bar .filter-menu .dropdown .dropdown-menu li a .fa.draft.app-state, .search-bar .filter-menu .dropdown .dropdown-menu li a .fa.limited.app-state, .search-bar .filter-menu .dropdown .dropdown-menu li a .fa.suspended.app-state {
    color: #F16623;
}
.search-bar .filter-menu .dropdown .dropdown-menu li a .fa.pending.app-state {
    color: #feca57;
}
.search-bar .filter-menu .dropdown .dropdown-menu li a .fa.scheduled.app-state {
    color: #00d5c5;
}
.search-bar .filter-menu .dropdown .dropdown-menu li a .fa.active-production.app-state, .search-bar .filter-menu .dropdown .dropdown-menu li a .fa.active.app-state {
    color: #ed3a80;
}
.search-bar .filter-menu .dropdown .dropdown-menu li a .fa.rejected.app-state {
    color: #ee5253;
}
.search-bar .filter-menu .dropdown .dropdown-menu li a .fa.paused.app-state {
    color: #8395a7;
}
.search-bar .filter-menu .dropdown .dropdown-menu li a .fa.completed.app-state {
    color: #025ba9;
}
.search-bar .filter-menu .dropdown .dropdown-menu li a .fa.app-type {
    color: #2980b9;
}
.search-bar .filter-menu .dropdown .dropdown-menu li a .fa.app-type {
    color: #c0392b;
}
.search-bar{} .filter-menu{} .any-api{} .dropdown-menu {
    left: 0;
}
.search-bar .filter-menu .any-status .dropdown-menu {
    left: 0;
}
.search-bar .filter-menu .any-type .dropdown-menu {
    left: -40px;
}
.search-bar .filter-menu .dropdown.open .dropdown-menu {
    max-height: 300px;
    opacity: 1;
}
.search-bar .filter-menu .no-padding {
    padding: 0;
}
.search-bar .search-bar__action-btn {
    margin-top: 25px;
}

@media screen and (max-width: 767px) {
    .search-bar .filtering-method .dropdown {
        margin-left: 0;
    }
    .search-bar .search-input .dropdown-menu {
        left: -160px;
    }
    .search-bar .search-input .dropdown-menu:before {
        left: 158px;
        border-left: 7px solid #ccc;
        border-right: 0;
    }
    .search-bar .search-input .dropdown-menu:after {
        left: 157px;
        border-left: 6px solid #fff;
        border-right: 0;
    }
    .search-bar .filter-menu .text-right {
        text-align: center;
    }
}
