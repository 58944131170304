/*Boreder radius css generate here*/
/*Transition css generate here*/
/*@font-face {
  font-family: Open Sans;
  font-style: normal;
  src: url(../../static/fonts/OpenSans-Regular.ttf);
}*/

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: local("Raleway Light"), local("Raleway-Light"), url(/registration-v3/fonts/-_Ctzj9b56b8RgXW8FArifk_vArhqVIZ0nv9q090hN8.woff2) format("woff2");
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local("Raleway"), url(/registration-v3/fonts/0dTEPzkLWceF7z0koJaX1A.woff2) format("woff2");
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: local("Raleway Bold"), local("Raleway-Bold"), url(/registration-v3/fonts/JbtMzqLaYbbbCL9X6EvaI_k_vArhqVIZ0nv9q090hN8.woff2) format("woff2");
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"), url(/registration-v3/fonts/EsvMC5un3kjyUhB9ZEPPwg.woff2) format("woff2");
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"), url(/registration-v3/fonts/1YwB1sO8YE1Lyjf12WNiUA.woff2) format("woff2");
}

/* line 1, ../scss/layout/TopMenu.scss */
header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1040;
}
/* line 7, ../scss/layout/TopMenu.scss */
header .navbar {
  z-index: 100;
  height: 60px;
  border: 0;
  border-radius: 0;
  background: #0055ab;
  margin-bottom: 0;
}
/* line 15, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
/* line 20, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-header .navbar-brand {
  margin-left: 0;
  color: white;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  height: auto;
  text-transform: uppercase;
}
/* line 31, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-header .navbar-brand img {
  display: inline-block;
  margin-right: 5px;
}
/* line 37, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-header .navbar-brand:hover {
  color: white;
  background-color: #294377;
}
/* line 45, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__menu-right {
  border-left: 1px solid #294377;
}
/* line 50, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right li a {
  color: white;
  padding: 0 20px;
  text-transform: uppercase;
  line-height: 60px;
}
/* line 57, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right li a:hover {
  color: white;
  background-color: #294377;
}
/* line 62, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right li a:focus {
  outline: none;
}
/* line 67, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .dropdown {
  padding: 0;
}
/* line 70, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .dropdown li {
  padding: 0;
}
/* line 74, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .dropdown li:hover {
  background-color: whitesmoke;
}
/* line 78, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .dropdown .dropdown-menu {
  padding: 0;
  -webkit-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.3) ;
  -moz-box-shadow: 0 0px 8px rgba(0, 0, 0, 0.3) ;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.3) ;
  border-radius: 0;
  margin-top: -10px;
  font-size: 13px;
}
/* line 85, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .dropdown .dropdown-menu .dropdown-header {
  background-color: whitesmoke;
  text-align: center;
}
/* line 89, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .dropdown .dropdown-menu .dropdown-header h5 {
  font-weight: 400;
}
/* line 93, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .dropdown .dropdown-menu .dropdown-header i {
  margin-right: 5px;
}
/* line 98, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .dropdown .dropdown-menu a {
  color: #646f82 !important;
  display: block;
  padding: 15px 20px !important;
  clear: both;
  font-weight: normal !important;
  line-height: 1.42857143 !important;
  white-space: nowrap;
  text-transform: none !important;
  background-color: transparent;
}
/* line 109, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .dropdown .dropdown-menu a i {
  margin-right: 5px;
}
/* line 114, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .dropdown .dropdown-menu a:hover {
  background-color: transparent;
}
/* line 119, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .dropdown .dropdown-menu:before {
  position: absolute;
  top: -7px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #cccccc;
  border-left: 7px solid transparent;
  content: '';
}
/* line 129, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .dropdown .dropdown-menu:after {
  position: absolute;
  top: -6px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid whitesmoke;
  border-left: 6px solid transparent;
  content: '';
}
/* line 141, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .open a {
  background-color: #294377;
}
/* line 145, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .open a:focus {
  background-color: transparent;
  color: white;
}
/* line 152, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__main-menu .dropdown-menu {
  width: 380px;
  padding: 0;
  color: #646f82;
}
/* line 157, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__main-menu .dropdown-menu .dropdown-body {
  padding: 20px;
}
/* line 160, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__main-menu .dropdown-menu .dropdown-body .main-menu__menu-item {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  z-index: 999;
  height: 110px;
  width: 110px;
}
/* line 168, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__main-menu .dropdown-menu .dropdown-body .main-menu__menu-item .main-menu__menu-item-link {
  padding: 5px 0 0 0 !important;
  text-decoration: none;
  position: absolute;
  border: 1px solid transparent;
  margin: 0;
}
/* line 175, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__main-menu .dropdown-menu .dropdown-body .main-menu__menu-item .main-menu__menu-item-link img {
  margin: 0 8px 0 8px;
}
/* line 180, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__main-menu .dropdown-menu .dropdown-body .main-menu__menu-item .main-menu__menu-item-name {
  display: block;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  width: 110px;
  padding-bottom: 10px;
  text-overflow: ellipsis;
}
/* line 191, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__main-menu .dropdown-menu .dropdown-body .main-menu__menu-item:hover:not(.main-menu__menu-item-link) {
  z-index: 1001;
}
/* line 195, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__main-menu .dropdown-menu .dropdown-body .main-menu__menu-item:hover:not(.main-menu__menu-item-link) a {
  border: 1px solid #cccccc;
}
/* line 199, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__main-menu .dropdown-menu .dropdown-body .main-menu__menu-item:hover {
  background-color: transparent;
}
/* line 202, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__main-menu .dropdown-menu .dropdown-body .main-menu__menu-item:hover .main-menu__menu-item-link {
  z-index: 1;
  background: rgba(243, 243, 243, 0.5);
}
/* line 206, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__main-menu .dropdown-menu .dropdown-body .main-menu__menu-item:hover .main-menu__menu-item-link .main-menu__menu-item-name {
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
/* line 216, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__main-menu .dropdown-menu:after {
  left: 343px;
}
/* line 220, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__main-menu .dropdown-menu:before {
  left: 342px;
}
/* line 226, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__notification .fa-bell {
  padding: 5px;
  border-radius: 50%;
  background-color: #294377;
}
/* line 232, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__notification .dropdown-menu {
  width: 300px;
}
/* line 235, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__notification .dropdown-menu a {
  text-decoration: none;
}
/* line 240, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__notification .dropdown-menu:after {
  left: 262px;
}
/* line 244, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__notification .dropdown-menu:before {
  left: 263px;
}
/* line 250, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__account-name .dropdown-menu {
  width: 100%;
}
/* line 254, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__account-name .dropdown-menu:after {
  left: 82px;
}
/* line 258, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__account-name .dropdown-menu:before {
  left: 83px;
}
/* line 262, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__account-name .user-profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 150px;
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
  -ms-border-radius: 150px;
  border-radius: 150px;
  margin-right: 5px;
  border: 2px solid #294377;
}
/* line 271, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__account-name .top-menu__account-name-dropdown {
  width: 300px;
}
/* line 274, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__account-name .top-menu__account-name-dropdown .dropdown-header {
  text-align: left;
  padding: 15px;
}
/* line 278, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__account-name .top-menu__account-name-dropdown .dropdown-header h5 {
  margin-top: 3px;
  margin-bottom: 3px;
  overflow: hidden;
  white-space: nowrap;
  width: 190px;
  text-overflow: ellipsis;
}
/* line 287, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__account-name .top-menu__account-name-dropdown .dropdown-header p {
  margin-bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
  width: 190px;
  text-overflow: ellipsis;
}
/* line 295, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__account-name .top-menu__account-name-dropdown .dropdown-header .user-profile-pic {
  width: 60px;
  height: 60px;
  float: left;
  margin-right: 10px;
  border: 2px solid white;
  display: table-cell;
}
/* line 304, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__account-name .top-menu__account-name-dropdown .dropdown-header .user-profile-details {
  display: table-cell;
}
/* line 307, ../scss/layout/TopMenu.scss */
header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__account-name .top-menu__account-name-dropdown .dropdown-header .user-profile-details i {
  width: 15px;
}

@media screen and (max-width: 767px) {
  /* line 325, ../scss/layout/TopMenu.scss */
  header .navbar .container-fluid {
    -webkit-box-shadow: 0 1px 3px #294377 ;
    -moz-box-shadow: 0 1px 3px #294377 ;
    box-shadow: 0 1px 3px #294377 ;
  }
  /* line 329, ../scss/layout/TopMenu.scss */
  header .navbar .container-fluid .navbar-header .navbar-toggle {
    border: 0;
    margin-top: 14px;
  }
  /* line 335, ../scss/layout/TopMenu.scss */
  header .navbar .container-fluid .navbar-header .navbar-toggle:hover,
  header .navbar .container-fluid .navbar-header .navbar-toggle:focus {
    background-color: transparent;
  }
  /* line 340, ../scss/layout/TopMenu.scss */
  header .navbar .container-fluid .navbar-collapse {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 !important;
    border-top: 0;
  }
  /* line 346, ../scss/layout/TopMenu.scss */
  header .navbar .container-fluid .navbar-collapse .navbar-nav {
    background-color: #0055ab;
    margin: 0 !important;
  }
  /* line 351, ../scss/layout/TopMenu.scss */
  header .navbar .container-fluid .navbar-collapse .navbar-nav li a {
    padding: 0 0 0 20px;
  }
  /* line 357, ../scss/layout/TopMenu.scss */
  header .navbar .container-fluid .navbar-collapse .navbar-nav .dropdown .dropdown-menu {
    margin-top: 0;
    box-shadow: none;
    width: 100%;
    background-color: white;
  }
  /* line 364, ../scss/layout/TopMenu.scss */
  header .navbar .container-fluid .navbar-collapse .navbar-nav .dropdown .dropdown-menu:before {
    display: none;
  }
  /* line 368, ../scss/layout/TopMenu.scss */
  header .navbar .container-fluid .navbar-collapse .navbar-nav .dropdown .dropdown-menu:after {
    display: none;
  }
  /* line 373, ../scss/layout/TopMenu.scss */
  header .navbar .container-fluid .navbar-collapse .navbar-nav .top-menu__main-menu {
    /*display: none;*/
  }
  /* line 377, ../scss/layout/TopMenu.scss */
  header .navbar .container-fluid .navbar-collapse .navbar-nav .top-menu__notification {
    /*display: none;*/
  }
  /* line 383, ../scss/layout/TopMenu.scss */
  header .navbar .container-fluid .navbar-collapse .navbar-nav .top-menu__account-name .dropdown-menu li {
    border-bottom: 1px solid #cccccc;
  }
  /* line 389, ../scss/layout/TopMenu.scss */
  header .navbar .container-fluid .navbar-collapse .navbar-nav .top-menu__menu-right {
    border-left: 0;
  }
}
@media screen and (max-width: 480px) {
  /* line 407, ../scss/layout/TopMenu.scss */
  header .navbar .container-fluid .navbar-collapse .navbar-right .top-menu__main-menu .dropdown-menu .dropdown-body {
    padding: 10px;
  }
}
/* line 1, ../scss/layout/Buttons.scss */
.btn:focus, .btn:hover {
  color: white;
}

/* line 5, ../scss/layout/Buttons.scss */
.primary-btn {
  color: white;
  background: #294377;
  background: linear-gradient(45deg, #294377, #0055ab);
}

/* line 11, ../scss/layout/Buttons.scss */
.primary-btn:hover {
  background: #294377;
  background: linear-gradient(45deg, #294377, #0055ab);
}

/* line 16, ../scss/layout/Buttons.scss */
.secondary-btn {
  color: white;
  background: #4e9d33;
  background: linear-gradient(45deg, #4e9d33, #5aac3e);
}

/* line 22, ../scss/layout/Buttons.scss */
.secondary-btn:hover {
  background: #4e9d33;
  background: linear-gradient(45deg, #4e9d33, #5aac3e);
}

/* line 27, ../scss/layout/Buttons.scss */
.secondary-action-btn {
  background-color: #dddddd;
  color: #333333;
}

/* line 33, ../scss/layout/Buttons.scss */
.secondary-action-btn:hover,
.secondary-action-btn:focus {
  background-color: #bbbbbb;
  color: #333333;
}

/* line 38, ../scss/layout/Buttons.scss */
.line-btn {
  border: 1px solid #cccccc;
  color: #333333;
}

/* line 43, ../scss/layout/Buttons.scss */
.transparent-btn {
  background-color: transparent;
}

/* line 47, ../scss/layout/Buttons.scss */
.btn-default, .btn-default:hover {
  color: #333333;
  box-shadow: none !important;
  border: 1px solid #cccccc !important;
}

/* line 53, ../scss/layout/Buttons.scss */
input[type='radio']:hover:before {
  background: #0055ab;
}

/* line 57, ../scss/layout/Buttons.scss */
input[type='radio']:checked:before {
  background: #294377;
}

/* line 61, ../scss/layout/Buttons.scss */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 13px;
  font-weight: normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: -10px;
}
/* line 74, ../scss/layout/Buttons.scss */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/* line 80, ../scss/layout/Buttons.scss */
.checkbox-container input:checked ~ .checkmark {
  background-color: #0055ab;
}
/* line 84, ../scss/layout/Buttons.scss */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
/* line 88, ../scss/layout/Buttons.scss */
.checkbox-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  margin-top: -2px;
  background-color: white;
  border: 1px solid #cccccc;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
/* line 101, ../scss/layout/Buttons.scss */
.checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* line 117, ../scss/layout/Buttons.scss */
.checkbox-container:hover input ~ .checkmark {
  background-color: #cccccc;
}

/* line 1, ../scss/layout/Common.scss */
body {
  background-color: whitesmoke;
}

/* line 5, ../scss/layout/Common.scss */
footer {
  color: #777777;
  background-color: whitesmoke;
}

/* line 10, ../scss/layout/Common.scss */
.white-bg {
  background-color: white;
}

/* line 14, ../scss/layout/Common.scss */
.gray-bg {
  background-color: whitesmoke;
}

/* line 18, ../scss/layout/Common.scss */
.form-control {
  color: #333333;
}

/* line 22, ../scss/layout/Common.scss */
.link {
  color: #0055ab;
}

/* line 26, ../scss/layout/Common.scss */
.link:hover {
  color: #294377;
}

/* line 30, ../scss/layout/Common.scss */
.sub-topic {
  color: #333333;
}

/* line 34, ../scss/layout/Common.scss */
.main-text {
  color: #5aac3e;
}

.main-text-for-uploadMultipleScanCopies {
  font-weight: bold;
  font-size: 12px;
  color:#777777;
}

/* line 38, ../scss/layout/Common.scss */
.sub-text {
  color: #646f82;
}

/* line 42, ../scss/layout/Common.scss */
.small-text {
  color: #bbbbbb;
}

/* line 46, ../scss/layout/Common.scss */
.dropdown .btn {
  color: #777777;
}

/* line 51, ../scss/layout/Common.scss */
.dropdown .btn:hover,
.dropdown .btn:focus {
  color: #333333;
}

/* line 55, ../scss/layout/Common.scss */
.dropdown-menu {
  border: 1px solid #eeeeee;
}

/* line 59, ../scss/layout/Common.scss */
.dropdown-menu a {
  color: #777777 !important;
}

/* line 63, ../scss/layout/Common.scss */
.sub-header {
  background-color: white;
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid white;
}

/* line 69, ../scss/layout/Common.scss */
.sub-header__actions .dropdown .btn {
  background-color: whitesmoke;
}

/* line 73, ../scss/layout/Common.scss */
.sub-header__time-item:before {
  background-color: #333333;
}

/* line 77, ../scss/layout/Common.scss */
.sub-header__time-item-info {
  color: #0055ab;
}

/* line 81, ../scss/layout/Common.scss */
.sub-header__info-popover {
  border: 1px solid #eeeeee;
  background-color: white;
}

/* line 86, ../scss/layout/Common.scss */
.page-card {
  background-color: white;
}

/* line 90, ../scss/layout/Common.scss */
.page-card__body {
  background-color: white;
}

/* line 94, ../scss/layout/Common.scss */
.page-card__heading {
  background-color: white;
  border-bottom: 1px solid #eeeeee;
}

/* line 102, ../scss/layout/Common.scss */
.page-card__heading-actions ul li a {
  border-left: 1px solid #eeeeee;
  color: #777777;
}
/* line 107, ../scss/layout/Common.scss */
.page-card__heading-actions ul li a:hover {
  background-color: whitesmoke;
}

/* line 114, ../scss/layout/Common.scss */
.dashboard__banner-area {
  background-color: #0055ab;
  color: white;
}

/* line 121, ../scss/layout/Common.scss */
.page-card__body label,
.main-section label {
  color: #777777;
}

/* line 125, ../scss/layout/Common.scss */
.main-section__heading {
  border-bottom: 1px solid #eeeeee;
}

/* line 129, ../scss/layout/Common.scss */
.main-section .input-group .input-group-addon {
  background-color: whitesmoke;
  border-top-color: #cccccc;
  border-right-color: #eeeeee;
  border-bottom-color: #cccccc;
  border-left-color: #cccccc;
}

/* line 137, ../scss/layout/Common.scss */
.common-modules {
  border: 1px solid #eeeeee;
  background-color: #f5f5f5;
}

/* line 142, ../scss/layout/Common.scss */
.common-modules__title {
  color: #777777;
}

/* line 146, ../scss/layout/Common.scss */
.modal-header {
  background-color: whitesmoke;
}

/* line 150, ../scss/layout/Common.scss */
.modal-header .modal-title {
  color: #777777;
}

/* line 154, ../scss/layout/Common.scss */
.modal-header .close {
  background-color: #eeeeee;
}

/* line 158, ../scss/layout/Common.scss */
.modal .input-group-addon {
  border: 1px solid #eeeeee;
  background-color: whitesmoke;
}

/* line 163, ../scss/layout/Common.scss */
.modal-footer {
  border-top: 1px solid #eeeeee;
}

/* line 167, ../scss/layout/Common.scss */
.table {
  border: 1px solid #eeeeee;
}

/* line 171, ../scss/layout/Common.scss */
.table thead tr {
  background-color: whitesmoke;
}

/* line 175, ../scss/layout/Common.scss */
.table tbody .row-title {
  color: #777777;
}

/* line 179, ../scss/layout/Common.scss */
.login-card {
  background-color: white;
}

/* line 183, ../scss/layout/Common.scss */
.login-card__body .input-group-addon {
  border-left: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}

/* line 189, ../scss/layout/Common.scss */
.login-page__footer {
  color: white;
  background-color: transparent;
}

/* line 194, ../scss/layout/Common.scss */
.login-page__footer a {
  color: #eeeeee;
}

/* line 199, ../scss/layout/Common.scss */
.panel-default .panel-heading {
  background-color: #eeeeee;
}

/* line 206, ../scss/layout/Common.scss */
.nav-tabs li a {
  color: #646f82;
  border-left: 3px solid transparent;
}
/* line 211, ../scss/layout/Common.scss */
.nav-tabs li a:hover {
  border-left: 3px solid #5aac3e;
}
/* line 217, ../scss/layout/Common.scss */
.nav-tabs li.active a, .nav-tabs li.active a:hover, .nav-tabs li.active a:focus {
  color: #5aac3e;
  border-left: 3px solid #5aac3e;
  background-color: transparent;
}

/* line 225, ../scss/layout/Common.scss */
.navbar-default .navbar-toggle .icon-bar {
  background-color: white;
}

/* line 231, ../scss/layout/Common.scss */
.tab-content .input-group label {
  color: #777777;
}

/* line 1, ../scss/layout/StepBar.scss */
.step-bar {
  margin-top: 20px;
}
/* line 4, ../scss/layout/StepBar.scss */
.step-bar .step-bar__step-content {
  display: inherit;
  text-align: center;
  flex-direction: row;
  box-sizing: border-box;
  float: left;
  width: 50%;
}
/* line 12, ../scss/layout/StepBar.scss */
.step-bar .step-bar__step-content .step-bar-container {
  padding-bottom: 10px;
}
/* line 15, ../scss/layout/StepBar.scss */
.step-bar .step-bar__step-content .step-bar-container .step-bar-border {
  height: 4px;
  background-color: #cccccc;
  width: 100%;
  position: relative;
}
/* line 21, ../scss/layout/StepBar.scss */
.step-bar .step-bar__step-content .step-bar-container .step-bar-border .step-indicator {
  width: 16px;
  height: 16px;
  background-color: #cccccc;
  border-radius: 8px;
  position: absolute;
  top: -6px;
  left: 45%;
}
/* line 31, ../scss/layout/StepBar.scss */
.step-bar .step-bar__step-content .step-bar-container .step-bar-border .step-indicator:before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: whitesmoke;
  display: block;
  border-radius: 4px;
  position: absolute;
  left: 4px;
  top: 4px;
}
/* line 45, ../scss/layout/StepBar.scss */
.step-bar .step-bar__step-content .step-bar__step-text-container {
  padding: 0 10px;
}
/* line 48, ../scss/layout/StepBar.scss */
.step-bar .step-bar__step-content .step-bar__step-text-container .sub-topic, .step-bar .step-bar__step-content .step-bar__step-text-container .main-topic {
  margin-top: 0;
  margin-bottom: 0;
}
/* line 56, ../scss/layout/StepBar.scss */
.step-bar .active .step-bar-border {
  background: linear-gradient(45deg, #5aac3e, #cccccc);
}
/* line 59, ../scss/layout/StepBar.scss */
.step-bar .active .step-bar-border .step-indicator {
  background-color: #5aac3e !important;
}
/* line 66, ../scss/layout/StepBar.scss */
.step-bar .step-done .step-bar-border {
  background-color: #5aac3e !important;
}
/* line 69, ../scss/layout/StepBar.scss */
.step-bar .step-done .step-bar-border .step-indicator {
  background-color: #5aac3e !important;
}
/* line 73, ../scss/layout/StepBar.scss */
.step-bar .step-done .step-bar-border .step-indicator:before {
  font-family: 'FontAwesome';
  content: "\f00c" !important;
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  background-color: transparent !important;
  color: white;
  font-size: 9px;
  top: 2px !important;
  left: 3px !important;
}

@media screen and (max-width: 480px) {
  /* line 92, ../scss/layout/StepBar.scss */
  .step-bar__step-text-container .main-topic {
    font-size: 13px !important;
  }
  /* line 96, ../scss/layout/StepBar.scss */
  .step-bar__step-text-container .sub-text {
    display: none;
  }
}
/* line 1, ../scss/layout/SubMenu.scss */
.sub-menu {
  background-color: #fff;
  height: 48px;
  border-bottom: 1px solid #eeeeee;
  font-size: 13px;
}
/* line 7, ../scss/layout/SubMenu.scss */
.sub-menu ul {
  padding-left: 0;
}
/* line 10, ../scss/layout/SubMenu.scss */
.sub-menu ul li {
  display: inline-block;
  text-align: center;
  margin-right: -3px;
}
/* line 15, ../scss/layout/SubMenu.scss */
.sub-menu ul li a {
  color: #646f82;
  line-height: 48px;
  padding: 12px 20px;
}
/* line 20, ../scss/layout/SubMenu.scss */
.sub-menu ul li a .badge-active {
  background-color: #5aac3e;
}
/* line 25, ../scss/layout/SubMenu.scss */
.sub-menu ul li a:hover {
  text-decoration: none;
}
/* line 31, ../scss/layout/SubMenu.scss */
.sub-menu .sub-menu__menu-items {
  float: left;
}
/* line 35, ../scss/layout/SubMenu.scss */
.sub-menu .sub-menu__menu-items .sub-menu__menu-items--active a {
  color: #5aac3e;
  border-bottom: 3px solid #5aac3e;
}
/* line 41, ../scss/layout/SubMenu.scss */
.sub-menu .sub-menu__menu-items a:hover {
  border-bottom: 3px solid #5aac3e;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
}
/* line 47, ../scss/layout/SubMenu.scss */
.sub-menu .sub-menu__view-mode {
  float: right;
  font-size: 14px;
}
/* line 50, ../scss/layout/SubMenu.scss */
.sub-menu .sub-menu__view-mode li {
  padding: 14px 18px 13px 18px;
  color: #646f82;
  cursor: pointer;
}
/* line 56, ../scss/layout/SubMenu.scss */
.sub-menu .sub-menu__view-mode li:hover {
  background-color: #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
/* line 61, ../scss/layout/SubMenu.scss */
.sub-menu .sub-menu__view-mode .sub-menu__view-mode--active {
  color: white;
  background-color: #5aac3e;
}
/* line 66, ../scss/layout/SubMenu.scss */
.sub-menu .sub-menu__view-mode .sub-menu__view-mode--active:hover {
  background-color: #4e9d33;
}
/* line 70, ../scss/layout/SubMenu.scss */
.sub-menu .sub-menu__view-mode .sub-menu__grid-view {
  border-left: 1px solid #eeeeee;
}
/* line 74, ../scss/layout/SubMenu.scss */
.sub-menu .sub-menu__view-mode .sub-menu__list-view {
  border-right: 1px solid #eeeeee;
}

.loader-container {
  text-align: center;
  color :#B7264D;
  size:25px;
  margin:10px;
}

.loader-container .sss {
  color: #0a0a0a;
}

@media screen and (max-width: 767px) {
  /* line 81, ../scss/layout/SubMenu.scss */
  .sub-menu {
    height: auto;
  }
  /* line 84, ../scss/layout/SubMenu.scss */
  .sub-menu .container {
    padding: 0;
  }
  /* line 88, ../scss/layout/SubMenu.scss */
  .sub-menu ul {
    margin-bottom: 0;
  }
  /* line 92, ../scss/layout/SubMenu.scss */
  .sub-menu .sub-menu__menu-items {
    text-align: center;
  }
  /* line 96, ../scss/layout/SubMenu.scss */
  .sub-menu .sub-menu__menu-items li a {
    padding: 15px 14px;
  }
  /* line 102, ../scss/layout/SubMenu.scss */
  .sub-menu .sub-menu__view-mode {
    display: none;
    float: left;
    width: 100%;
  }
  /* line 107, ../scss/layout/SubMenu.scss */
  .sub-menu .sub-menu__view-mode li {
    display: inline-block;
    width: 50%;
  }
  /* line 111, ../scss/layout/SubMenu.scss */
  .sub-menu .sub-menu__view-mode li a {
    padding: 14px 18px 15px 18px;
  }
  /* line 116, ../scss/layout/SubMenu.scss */
  .sub-menu .sub-menu__view-mode .sub-menu__view-mode--active {
    background-color: #5aac3e;
  }
  /* line 120, ../scss/layout/SubMenu.scss */
  .sub-menu .sub-menu__view-mode .sub-menu__view-mode--active:hover {
    background-color: #4e9d33;
  }
  /* line 124, ../scss/layout/SubMenu.scss */
  .sub-menu .sub-menu__view-mode li:hover {
    background-color: white;
  }
  /* line 128, ../scss/layout/SubMenu.scss */
  .sub-menu .sub-menu__view-mode .sub-menu__grid-view a {
    border-left: 0;
  }
  /* line 132, ../scss/layout/SubMenu.scss */
  .sub-menu .sub-menu__view-mode .sub-menu__list-view a {
    border-right: 0;
  }
}
