html {
    position: relative;
    min-height: 100%;
}

body {
    font-size: 13px;
    font-family: "Open Sans",sans-serif;
    padding-top: 60px;
    padding-bottom: 50px;
}

footer {
    padding: 10px;
    margin-top: 20px;
    text-align: center;
    font-size: 11px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

.btn {
    padding: 10px 15px;
    font-size: 12px;
    border: 0;
    letter-spacing: 1px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    -webkit-transition: all 0.2s ease 0s;
    -moz-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
}

.btn:focus, .btn:hover {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.btn:active {
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.small-btn {
    padding: 8px 10px;
    font-size: 12px;
}

.line-btn {
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

button:focus,
input:focus,
select:focus {
    outline: none !important;
}

input[type='radio']:before {
    -webkit-transition: all 0.2s ease 0s;
    -moz-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
}

input[type="file"] {
    display: none;
}

input[type="file"]:disabled+label {
    color: #ccc;
    background: #eee;
}

.checkbox-inline {
    padding-left: 0;
}

.input-group {
    width: 100%;
}

.form-control {
    -webkit-border-radius: 0;
    border-radius: 0;
    font-size: 12px;
    min-height: 40px;
}

.full-width {
    width: 100%;
}

.main-topic {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
}

.sub-topic {
    font-weight: 700;
}

.main-text {
    margin-bottom: 3px;
}

.sub-text {
    font-size: 12px;
}

.small-text {
    font-size: 11px;
    margin: 0;
}

.bold-font {
    font-weight: bold;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-right-20 {
    margin-right: 20px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-left-20 {
    margin-left: 20px;
}

.no-padding-left {
    padding-left: 0;
}

.no-padding-right {
    padding-right: 0;
}

.no-padding {
    padding: 0;
}

.no-margin-top {
    margin-top: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.small-column {
    width: 100px;
}

.medium-column {
    width: 220px;
}

.large-column {
    width: 350px;
}

.error-message,
.success-message {
    font-size: 12px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 2px 4px 0 #ececec;
    box-shadow: 0 2px 4px 0 #ececec;
}

.error-message {
    background-color: #ffe8e6;
    color: #e74c3c;
    border: 1px solid #ff9c9c;
}

.error-message .input-group,
.success-message .input-group {
    margin-bottom: 0 !important;
}

.error-message .input-group-addon,
.success-message .input-group-addon {
    border: 0 !important;
    border-radius: 0;
    padding: 6px 8px;
    color: #fff !important;
}

.error-message .input-group-addon {
    background-color: #ff7979 !important;
}

.success-message .input-group-addon {
    background-color: #57ae72 !important;
}

.success-message {
    background-color: #e7ffee;
    color: #5fba7d;
    border: 1px solid #5fba7d;
}

.message__text {
    padding: 5px;
    text-align: left;
}

.common-message-area {
    background-color: #ffffff;
    padding: 20px 20px 20px 20px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    margin-bottom: 20px;
    -webkit-box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
}

.common-message-area .input-group-addon i {
    font-size: 55px;
}

.common-message-area {
    background-color: #ffffff;
}

.common-message-area__warning {
    border: 1px solid rgba(240, 173, 78, 0.3);
}

.common-message-area__info {
    border: 1px solid rgba(78, 128, 240, 0.3);
}

.common-message-area__warning .input-group-addon {
    border-color: transparent;
    background-color: #ffffff;
    color: #f0ad4e;
}

.common-message-area__info .input-group-addon {
    border-color: transparent;
    background-color: #ffffff;
    color: #0055ab;
}

.common-message-area.common-message-area__small {
    padding: 10px;
}

.common-message-area__small .sub-text {
    margin-top: 10px;
}

.common-message-area__small .input-group-addon i {
    font-size: 25px;
}

.field-error {
    color: #ff7979;
    font-size: 12px;
    text-align: left;
}

.form-error {
    color: #ff7979;
    font-size: 12px;
    text-align: left;
}

.required-field {
    color: red;
}

.dropdown .btn {
    box-shadow: none;
}

.dropdown-menu {
    font-size: 13px;
    padding: 0;
    margin: 0;
    display: block;
    opacity: 0;
    overflow: hidden;
    max-height: 0;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.dropdown-menu a {
    padding: 12px 15px !important;
    cursor: pointer;
}

.dropdown.open .dropdown-menu {
    max-height: 400px;
    opacity: 1;
}

.dropdown:hover .dropdown-menu {
    display: block;
    max-height: 400px;
    opacity: 1;
}

/*Sub Header css*/
.sub-header {
    position: fixed;
    right: 0;
    left: 380px;
    top: 60px;
    height: 60px;
    z-index: 960;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.sub-header__actions {
    position: relative;
    padding-top: 12px;
    text-align: right;
}

.sub-header__actions i {
    width: 20px;
}

.sub-header__actions .btn {
    margin-left: 5px;
}

.sub-header__actions .dropdown-menu {
    left: auto;
    right: 0;
}

.sub-header__actions .line-btn {
    padding: 5px;
}

.sub-header__actions .line-btn i {
    border: 0;
    padding: 0;
    width: 20px;
}

.sub-header__details {
    position: relative;
    padding: 8px 0 0 10px;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.sub-header__details .request-list__status {
    left: 55px;
    top: 26px;
    right: auto;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.sub-header__details .request-list__request-image {
    width: 35px;
    height: 35px;
    margin-top: 4px;
    margin-right: 10px;
}

.sub-header__details .request-list__request-name .sub-text {
    font-weight: bold;
}

.sub-header__time {
    margin: 3px 0 5px 150px;
}

.sub-header__time-item {
    margin-right: 15px;
    padding-left: 10px;
    position: relative;
}

.sub-header__time-item:before {
    content: '';
    position: absolute;
    padding: 3px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    left: 0;
    top: 5px;
}

.sub-header__time-item-info {
    padding-left: 0;
}

.sub-header__time-item-info:before {
    display: none;
}

.sub-header__time-item-info:hover .sub-header__info-popover {
    display: block;
    max-height: 400px;
    opacity: 1;
}

.sub-header__info-popover {
    font-size: 13px;
    padding: 7px;
    margin: 0;
    display: block;
    opacity: 0;
    overflow: hidden;
    max-height: 0;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    position: absolute;
    top: 100%;
    z-index: 1000;
    min-width: 160px;
    list-style: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-radius: 4px;
}

.content-block {
    margin-top: 40px;
}

/*Card css*/
.page-card__heading {
    padding: 10px 20px;
    position: relative;
}

.page-card__heading-actions {
    position: absolute;
    right: 0;
    top: 0;
}

.page-card__heading-actions ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.page-card__heading-actions ul li {
    display: inline-block;
    float: left;
}

.page-card__heading-actions ul li a {
    padding: 17px 18px;
    display: block;
    cursor: pointer;
}

.page-card__icon-area {
    text-align: center;
}

.page-card__icon-area img {
    margin-top: -30px;
}

.page-card__body {
    padding: 20px;
    text-align: left;
}

.page-card__body .input-group {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
}

.page-card__body label {
    font-weight: bold;
    font-size: 12px;
}

.page-banner-area {
    padding-top: 10px;
    text-align: center;
}

.dashboard__banner-area {
    padding: 70px 0 100px 0;
    background-image: url(/registration-v3/a9d76648d0bd5768007ac8fc22f8b0aa.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.dashboard__banner-area-tagline {
    text-align: left;
}

.dashboard__banner-area-logo {
    text-align: right;
}

.dashboard__banner-area-main-logo {
    padding-top: 19px;
}

.dashboard-card-section {
    padding: 0;
    max-width: 100%;
    margin: 0 auto;
    list-style: none;
    text-align: center;
}

.dashboard-card-section__item {
    display: inline-block;
    margin: 0;
    padding: 20px;
    text-align: center;
    position: relative;
}

.main-section {
    padding: 20px;
    margin-bottom: 20px;
    margin-top: 15px;
}

.main-section__heading {
    padding-bottom: 5px;
    margin-bottom: 15px;
}

.main-section__heading .main-text {
    margin-top: 0;
}

.main-section .input-group {
    width: 100%;
}

.main-section .input-group .form-control {
    -webkit-border-radius: 0;
    border-radius: 0;
}

.main-section .input-group .file-upload {
    padding-top: 9px;
}

.login-body .input-group .input-group-addon.error-input-field,
.login-body .input-group .form-control.error-input-field,
.main-section .input-group .input-group-addon.error-input-field,
.main-section .input-group .form-control.error-input-field {
    border-bottom: 2px solid #ff7979;
}

.main-section .input-group .input-group-addon {
    border-radius: 0;
}

.main-section label {
    font-weight: bold;
    font-size: 12px;
}

.common-access-block {
    margin-top: -55px;
}

.common-access-block a {
    text-decoration: none;
}

.common-modules {
    padding: 20px 55px;
    height: 190px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.05);
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.2s ease 0s;
    transition: all 0.2s ease 0s;
}

.common-modules:hover {
    -webkit-box-shadow: 1px 5px 15px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 5px 15px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 5px 15px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
}

.common-modules img {
    /*width: 100px;*/
}

.common-modules__title {
    padding-top: 10px;
    font-weight: bold;
}

/*Modal css*/
.modal-header {
    border-bottom: 0;
    padding: 10px 15px;
}

.modal-header .modal-title {
    padding-top: 5px;
    text-transform: uppercase;
    font-weight: 700;
}

.modal-header .close {
    padding: 5px 10px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.modal-body__with-actions {
    padding-top: 30px !important;
}

.modal .input-group {
    margin-bottom: 5px;
    width: 100%;
}

.modal .input-group-addon {
    font-size: 13px;
}

.modal .input-group-addon.with-text {
    min-width: 60px;
}

.responsive-modal .modal-dialog {
    width: 380px !important;
}

.responsive-modal .modal-dialog:before {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0,0,0,.5);
}

/*Table css*/
.table-without-border td {
    border-top: 0 !important;
}

.table tbody .row-title {
    font-weight: 600;
}

/*Tooltip*/
.tooltip-toggle::before {
    position: absolute;
    top: 50px;
    left: 0;
    background-color: black;
    border-radius: 5px;
    color: #fff;
    content: attr(data-tooltip);
    padding: 0.6rem;
    text-transform: none;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 1050;
}

.tooltip-toggle::after {
    position: absolute;
    top: 45px;
    left: 25px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
    content: " ";
    font-size: 0;
    line-height: 0;
    margin-left: -5px;
    width: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 1050;
}

.tooltip-toggle:hover::before,
.tooltip-toggle:hover::after {
    opacity: 1;
    -webkit-transition: all 0.75s ease;
    transition: all 0.75s ease;
    z-index: 1050;
}

.tooltip-toggle::before,
.tooltip-toggle::after {
    color: #efefef;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    opacity: 0;
    pointer-events: none;
    text-align: center;
    z-index: 1050;
}

.notification-section {
    color: #FFFFFF;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1040;
}

.notification-section.success {
    background: #0055ab;
    background: linear-gradient(45deg, rgba(0, 85, 171, 0.95), rgba(90, 170, 60, 0.95));
}

.notification-section.info {
    background: #3945b5;
    background: linear-gradient(45deg, rgba(57, 69, 181, 0.95), rgba(87, 103, 255, 0.95));
}

.notification-section.failure {
    background: #ff7979;
    background: linear-gradient(45deg, #e45151ed, rgba(255, 121, 121, 0.95));
}

.notification-section__header {
    padding: 10px 10px;
    text-align: center;
    float: left;
}

.notification-section__header-icon {
    font-size: 40px;
    line-height: 0;
}

.notification-section__close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    padding: 10px;
}

.notification-section__body {
    padding: 13px 10px 10px 50px;
    text-align: left;
}

/*MsisdnVerificationForm css*/
.login-back {
    background-image: url(/registration-v3/9f2fc24604318ada2ceea20a7d5de065.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.login-overlay {
    background-color: rgba(25,29,34,.5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.login-page {
    margin-bottom: 10px;
    background-color: #fff;
    -webkit-border-top-left-radius: 20px;
    border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 20px;
    border-top-right-radius: 20px;
    -webkit-border-bottom-left-radius: 10px;
    border-bottom-left-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    border-bottom-right-radius: 10px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
}

.login-header {
    border-bottom: 1px solid #eee;
    background-color: #f8f8f8;
    padding: 20px;
    /*background-image: url("../images/login-header-back.png");*/
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
}

.login-body {
    padding: 20px 20px 30px 20px;
}

.login-body .input-group {
    margin-bottom: 3px;
    width: 100%;
}

.login-body .form-control {
    box-shadow: none;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.login-body .input-group-addon {
    color: #999;
    background-color: #f9f9f9;
}

.login-body .input-group-btn .btn {
    min-height: 40px;
    margin-left: -2px;
}

.login-body .input-group-addon i {
    width: 15px;
}

.login-body__or-seperator {
    display: block;
    background-color: #fff;
    margin: 0 auto;
    margin-bottom: 25px;
    width: 50px;
    height: 50px;
    padding: 16px 10px;
    border: 1px dashed #bbb;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}

.login-body__or-seperator-line {
    border-top: 1px dashed #bbb;
    margin-top: 25px;
    margin-bottom: -27px;
}

.login-body__left-link {
    text-align: left;
}

.login-body__right-link {
    text-align: right;
}

.login-footer {
    padding: 20px;
    text-align: center;
}

.nav-tabs {
    margin-bottom: 15px;
    border-bottom-color: transparent;
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    cursor: default;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
}

.nav-tabs li a {
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border-radius: 0;
}

.panel-group .panel {
    border-radius: 0;
}

.panel-title {
    font-size: 14px;
    font-weight: bold;
}
.popup {
    position: fixed;
    z-index: 1050;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid black;
    max-width: 80vw; /* Set maximum width relative to viewport width */
    max-height: 80vh; /* Set maximum height relative to viewport height */
    overflow: auto;
}

.popup-image {
    max-width: calc(100% - 40px); /* Set maximum width for the image inside the popup */
    max-height: calc(100% - 40px); /* Set maximum height for the image inside the popup */
    margin: 20px; /* Add margin around the image */
}
.popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    background-color: #ccc;
    border: none;
    cursor: pointer;
}

.rw-widget-input, .rw-filter-input {
    -webkit-border-radius: 0;
    border-radius: 0;
    font-size: 12px;
    min-height: 40px;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 4px 12px;
    height: 40px;
    padding: 10px 10px;
}

.custom-file-re-upload {
    position: absolute;
    border-radius: 3px;
    top: -111px;
    background-color: #fff;
    right: 44px;
    opacity: 0.8;
    width: 50px;
}

.custom-file-re-upload-btn {
    top: -106px;
}

.uploaded-image-div {
    width: 128px;
    height: 155px;
    border-radius: 3px;
    margin-top: -176px;
}

.uploaded-image-preview-div {
    margin-top: -167px;
}

.custom-file-upload-square-btn {
    padding: 65px 63px;
    height: 150px;
}

.pdf-page-canvas {
    display: block;
    margin: 5px auto;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.warning-padding {
    margin-left: 15px;
    margin-right: 15px;
}

.common-message-area-small {
    padding: 10px;
}

.form-input-fields {
    margin-bottom: 10px;
    text-align: left;
}

.image-wrapper {
    position: relative;
    margin-top: -45px;
    width: 128px;
}


.image-download-icon {
    position: absolute;
    border-radius: 3px;
    top: -111px;
    right: 0;
    opacity: 0.8;
}

.uploaded-image-download-icon {
    top:-106px;
}

.custom-file-upload-third {
    padding: 55px 72px 30px 37px;
}
.image-alinments{
    margin: 23px 0 0 0;
}

.link-cursor:hover {
  cursor:pointer;
}

.custom-popup {
    top: 20%;
}

.custom-modal {
    position: fixed;
    z-index: 1050;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.custom-popup-header{
    border-radius: inherit;
}

@media screen and (max-width: 769px) {
    .sub-header {
        left: 350px;
        height: auto;
    }

    .sub-header__actions {
        text-align: left;
        padding-bottom: 15px;
    }

    .sub-header__actions .btn {
        margin-left: 0;
        margin-right: 3px;
        padding: 6px 10px;
    }

    .responsive-modal .modal-dialog {
        width: 350px !important;
    }

    .common-modules {
        padding: 20px 30px;
    }
}

@media screen and (max-width: 480px) {
    .sub-header {
        left: 0;
    }

    .sub-header__actions {
        padding-top: 6px;
    }

    .sub-header__actions .btn {
        padding: 6px 5px;
    }

    .sub-header__time {
        margin: 25px 0 0 0;
    }

    .sub-header__time-item {
        display: block;
    }

    .sub-header__details .request-list__request-image {
        margin-left: 40px;
    }

    .sub-header__details .request-list__status {
        left: 94px;
    }

    .main-section__heading {
        padding-bottom: 0;
    }

    .main-section__heading .main-topic,
    .page-banner-area .main-topic {
        font-size: 18px;
    }

    .dashboard__banner-area {
        padding-top: 30px;
        padding-bottom: 60px;
    }

    .dashboard__banner-area-tagline,
    .dashboard__banner-area-logo {
        text-align: center;
    }

    .dashboard__banner-area-main-logo {
        width: 100px;
    }

    .dashboard__banner-area .main-topic {
        font-size: 22px;
    }

    .dashboard-card-section__item {
        padding: 2px 0;
    }

    .common-modules {
        padding: 5px 15px;
        height: auto;
    }

    .common-modules__title {
        padding-top: 0;
        padding-bottom: 10px;
    }

    .common-access-block {
        margin-top: -35px;
    }

    .responsive-modal .modal-dialog {
        width: 325px !important;
    }

    .login-body__right-link {
        text-align: left;
    }
}

@media screen and (max-width: 320px) {
    .dashboard__banner-area-main-logo {
        width: 100%;
    }
}

.common-list {
    margin: 0;
    padding: 0;
}

.common-list li{
    list-style:none;display:inline-block;
    margin-right:20px
}